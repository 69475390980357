<template>
    <v-row justify="center">
        <v-dialog v-model="dialog" persistent max-width="600px">
            <v-card>
                <v-card-title>
                    <h5 class="lfont">{{ $t("scanTime.update_shift.title") }}</h5>
                </v-card-title>
                <v-card-text>
                    <v-container class="lfont">
                        <v-row>
                            <v-col cols="12" sm="5" md="5">
                                <label for="">{{ $t("scanTime.update_shift.started_at") }}</label>
                                <DatePicker class="date-time" style="width: 100%" type="date" valueType="format"
                                    slot="activator" :append-to-body="true" name="founding_date"
                                    v-model="submit_form.started_at"></DatePicker>
                            </v-col>
                            <v-col cols="12" sm="5" md="5">
                                <label for="">{{ $t("scanTime.update_shift.ended_at") }}</label>
                                <DatePicker class="date-time" style="width: 100%" type="date" valueType="format"
                                    slot="activator" :append-to-body="true" name="founding_date"
                                    v-model="submit_form.ended_at"></DatePicker>
                            </v-col>
                            <v-col cols="12" sm="2" md="2">
                                <label for="">{{ $t("scanTime.update_shift.status") }}</label>
                                <v-switch v-model="submit_form.status" color="primary" value="active"></v-switch>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="blue darken-1" text @click="handle_submit" class="lfont">
                        {{ $t("scanTime.update_shift.btn_save") }}
                    </v-btn>
                    <v-btn color="blue darken-1" text @click="$emit('close', false)" class="lfont">
                        {{ $t("scanTime.update_shift.btn_cancel") }}
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-row>
</template>

<script>
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
export default {
    name: "ModalEdit",
    components: {
        DatePicker
    },
    props: {
        dialog: {
            type: Boolean,
            default: true
        },
        update_shift_item: {
            type: Object,
            default: () => { }
        }
    },
    data() {
        return {
            submit_form: {
                status: '',
                started_at: '',
                ended_at: ''
            }
        }
    },
    watch: {
        update_shift_item(val) {
            this.submit_form.started_at = val.started_at;
            this.submit_form.ended_at = val.ended_at;
            this.submit_form.status = val.status;
        },
        'submit_form.status'(val) {
            if (val == 'active') {
                this.submit_form.status = 'active';
            } else {
                this.submit_form.status = 'expire';
            }
        }
    },
    methods: {
        handle_submit() {
            const id = this.update_shift_item.clock_time_id;
            this.$emit("close", true);
            this.$emit("submit", true);
            this.$axios
                .post(`company/employee-scan-time/update/${id}`, this.submit_form)
                .then((res) => {
                    if (res) {
                        this.$emit("success", true);
                    }
                })
                .catch((error) => {
                    console.log(error);
                });
        }
    }
}
</script>

<style lang="scss" scoped></style>