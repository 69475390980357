<template>
  <div class="main-container">
    <div class="section-topic">
      <div class="title-header"></div>
    </div>
    <div class="section-action">
      <v-row>
        <v-col cols="12" lg="3" md="3">
          <label class="label-input">{{ $t("Employee.search_filter") }}</label>
          <v-text-field
            class="btn btn-color btn-border btn-border-color"
            outlined
            dense
            prepend-inner-icon="mdi-magnify"
            :placeholder="$t('Search.search')"
            v-model="employee_name"
            @keypress.enter="fetchAllEmployee"
          >
          </v-text-field>
        </v-col>
        <v-col cols="12" md="3" lg="3">
          <label class="label-input">{{ $t("ScanPoint.dept") }}</label>
          <v-select
            class="btn btn-color btn-border btn-border-color"
            outlined
            dense
            placeholder="ເລືອກພະແນກ ..."
            hide-details
            :items="listDepartment"
            item-value="id"
            item-text="name"
            v-model="department_id"
            @change="fetchAllEmployee"
          >
          </v-select>
        </v-col>
        <v-col cols="12" md="3" lg="3">
          <label class="label-input">{{ $t("ScanPoint.branch") }}</label>

          <v-select
            class="btn btn-color btn-border btn-border-color"
            outlined
            dense
            placeholder="ເລືອກສາຂາ ..."
            hide-details
            :items="listBranch"
            item-value="id"
            item-text="branch_name"
            v-model="branch_id"
            @change="fetchAllEmployee"
          >
          </v-select>
        </v-col>
        <v-col cols="12" md="2" lg="2">
          <div class="mt-6">
            <v-btn class="ma-2 btn" color="info" @click="AllEmployee">
              All
            </v-btn>
          </div>
        </v-col>
      </v-row>
    </div>
    <div class="section-form-content">
      <div class="block-content">
        <v-autocomplete
          filled
          chips
          color="blue-grey lighten-4"
          label="Select Employee"
          item-text="name"
          item-value="user_id"
          multiple
          outlined
          :items="allEmployee"
          hide-selected
          v-model="data.employee"
        >
          <template v-slot:selection="data">
            <v-chip
              v-bind="data.attrs"
              :input-value="data.selected"
              close
              @click="data.select"
              @click:close="removeItemFromTable(data.item)"
            >
              <v-avatar left>
                <v-img :src="data.item.urlProfile"></v-img>
              </v-avatar>
              {{ data.item.name }}
            </v-chip>
          </template>
          <template v-slot:item="data">
            <template v-if="typeof data.item !== `object`">
              <v-list-item-content v-text="data.item"></v-list-item-content>
            </template>
            <template v-else>
              <v-list-item-avatar>
                <img :src="data.item.urlProfile" alt="" />
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title v-html="data.item.name"></v-list-item-title>
                <v-list-item-subtitle
                  v-html="data.item.surname"
                ></v-list-item-subtitle>
              </v-list-item-content>
            </template>
          </template>
        </v-autocomplete>
        <v-btn
          class="ma-2 btn"
          color="primary"
          @click="saveEmployeeClockInCamera"
        >
          Save
        </v-btn>
      </div>
    </div>
    <Loading v-if="isLoading" />
  </div>
</template>

<script>
import Loading from "@/components/Loading";
export default {
  data() {
    return {
      allEmployee: [],
      attractEmployee: [],
      data: {
        employee: [],
      },
      listDepartment: [],
      listBranch: [],
      department_id: "",
      branch_id: "",
      isLoading: false,
      employee_name: "",
    };
  },
  components: {
    Loading,
  },
  methods: {
    removeItemFromTable(item) {
      const index = this.data.employee.indexOf(item.user_id);
      if (index >= 0) {
        this.data.employee.splice(index, 1);
      }
    },
    saveEmployeeClockInCamera() {
      this.isLoading = true;
      this.$axios
        .post(`company/add/employee/clockIn/camera`, {
          employees: this.data.employee,
        })
        .then((res) => {
          this.isLoading = false;
          this.$router
            .push({
              name: "scan_camera.index",
            })
            .catch(() => {});
        })
        .catch(() => {
          this.isLoading = false;
        });
    },
    fetchAllEmployee() {
      this.isLoading = true;
      this.$axios
        .get(`company/all-employee-name`, {
          params: {
            name: this.employee_name,
            department_id: this.department_id,
            branch_id: this.branch_id,
          },
        })
        .then((res) => {
          this.isLoading = false;
          this.allEmployee = res.data.data;
        })
        .catch(() => {
          this.isLoading = false;
        });
    },
    AllEmployee() {
      this.department_id = "";
      this.branch_id = "";
      this.fetchAllEmployee();
    },
    fetchDepartment() {
      this.$axios.get(`company/list/departments/selected`, {}).then((res) => {
        if (res.status === 200) {
          this.listDepartment = res.data.data;
        }
      });
    },
    fetchBranch() {
      this.$axios
        .get(`company/list/company/branches`)
        .then((res) => {
          if (res) {
            this.listBranch = res.data.companyBranches;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
  computed: {
    selectedEmployees() {
      return this.data.employee.map((id) => {
        const find = this.allEmployee.filter((item) => {
          return id === item.id;
        });
        if (find.length) {
          return find[0];
        }
        return {};
      });
    },
  },
  created() {
    this.fetchDepartment();
    this.fetchBranch();
  },
};
</script>

<style  scoped lang="scss">
.block-content {
  padding-top: 20px;
}
</style>