<template>
  <div class="main-container">
    <div class="section-topic">
      <div class="title-header">
        <h1>{{ $t("department.title") }}</h1>
      </div>
    </div>
    <div class="section-action">
      <div class="search-action">
        <v-text-field
          class="btn btn-color btn-border btn-border-color"
          outlined
          dense
          prepend-inner-icon="mdi-magnify"
          :placeholder="$t('Search.search')"
          v-model="searchItem"
          @keypress.enter="searchFilterItem"
        >
        </v-text-field>
      </div>
      <div class="btn-new-create-action">
        <v-btn class="btn-create" @click="onCreate()">{{
          $t("department.create")
        }}</v-btn>
      </div>
    </div>
    <div class="section-form-content">
      <div class="block-content">
        <table class="table table-view" v-if="listDepartment.length > 0">
          <thead>
            <tr>
              <th class="text-left">{{ $t("department.no") }}</th>
              <th class="text-left">{{ $t("department.name") }}</th>
              <th class="text-left">{{ $t("department.created") }}</th>
              <th class="text-left">{{ $t("department.updated") }}</th>

              <th class="text-left"></th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item, idx) in listDepartment" :key="idx">
              <td>{{ pagination.current_page * 10 - 10 + idx + 1 }}</td>
              <td>
                {{ item.name }}
              </td>
              <td>
                {{ item.created_at }}
              </td>
              <td>
                {{ item.updated_at }}
              </td>
              <td></td>
              <td class="text-end">
                <v-menu transition="slide-y-transition" bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn outlined v-bind="attrs" v-on="on">
                      <i class="fas fa-ellipsis-v btn-ellipsis"></i>
                    </v-btn>
                  </template>
                  <v-list>
                    <v-list-item>
                      <v-list-item-title
                        class="btn-edit"
                        @click="onEdit(item.id)"
                        >Edit
                      </v-list-item-title>
                    </v-list-item>
                    <v-list-item>
                      <v-list-item-title
                        class="btn-delete"
                        @click="onDeleted(item.id)"
                        >Delete
                      </v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </td>
            </tr>
          </tbody>
        </table>
        <defaultTableNoResult v-else />

        <Pagination
          class="mt-5 mb-5"
          v-if="pagination.total_pages > 1"
          :pagination="pagination"
          :offset="offset"
          @paginate="fetchDepartment"
        >
        </Pagination>
        <Loading v-if="isLoading" />
        <ModalEdit>
          <template v-slot="{ close }">
            <EditDepartment
              :department="department"
              @close="close"
              @success="fetchDepartment"
            />
          </template>
        </ModalEdit>
        <ModalDelete>
          <template v-slot="{ close }">
            <DeleteDepartment
              :departmentId="departmentId"
              @close="close"
              @success="fetchDepartment"
            />
          </template>
        </ModalDelete>
      </div>
    </div>
  </div>
</template>

<script>
import Pagination from "../../../components/Paginate/Pagination";
import Loading from "../../../components/Loading";
import defaultTableNoResult from "../../../components/defaultTableNoResult";
import EditDepartment from "@/views/MainCompany/Department/CRUD/EditDepartment";
import DeleteDepartment from "@/views/MainCompany/Department/CRUD/DeleteDepartment";

export default {
  components: {
    Pagination,
    Loading,
    defaultTableNoResult,
    EditDepartment,
    DeleteDepartment,
  },
  data() {
    return {
      offset: 10,
      pagination: {},
      per_page: 10,

      isLoading: true,
      listDepartment: [],
      department: {},
      departmentId: "",
      searchItem: "",
    };
  },
  methods: {
    searchFilterItem() {
      this.fetchDepartment();
    },
    filterDepartment(departmentId) {
      return (
        this.listDepartment.filter((item) => {
          return item.id == departmentId;
        })[0] || {}
      );
    },

    onCreate() {
      this.$router
        .push({
          name: "department.create",
        })
        .catch(() => {});
    },

    onEdit(departmentId) {
      this.department = {
        ...this.filterDepartment(departmentId),
      };
      this.$store.commit("modalEdit_State", true);
    },

    onDeleted(departmentId) {
      this.departmentId = departmentId;
      this.$store.commit("modalDelete_State", true);
    },

    fetchDepartment() {
      this.isLoading = true;
      this.$axios
        .get(`company/list/departments`, {
          params: {
            page: this.pagination.current_page,
            per_page: this.per_page,
            filter: this.searchItem,
          },
        })
        .then((res) => {
          if (res.status === 200) {
            this.isLoading = false;
            this.listDepartment = res.data.data.data;
            this.pagination = res.data.data.pagination;
            if (!this.listDepartment.length > 0) {
              this.pagination.current_page = this.pagination.current_page - 1;
            }
          }
          this.isLoading = false;
        })
        .catch((error) => {
          console.log(error);
          this.isLoading = false;
        });
    },
  },
  created() {
    this.fetchDepartment();
  },
};
</script>

<style scoped lang="scss">
</style>
