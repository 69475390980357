<template>
  <div class="content-layout">
    <div class="content lfont">
      <h4 color="primary" class="lfont">
        <i class="fa fa-user" aria-hidden="true"></i>
        {{ $t('ScanPoint.add_new.name') }}:
        <span class="user">{{ employee.name }} {{ employee.surname }}</span>
      </h4>
      <hr />
      <v-card flat>
        <v-card-text>
          <v-container fluid>
            <v-row>
              <v-col cols="12" sm="12" md="12">
                <v-text-field class="btn btn-color" outlined dense prepend-inner-icon="mdi-magnify"
                  :placeholder="$t('Search.search')" @change="fetchScanPoint($event)">
                </v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" sm="3" md="3" v-for="(item, index) in employeeScanPoint" :key="index">
                <v-checkbox v-model="data.clock_in_zone_id" :label="item.name" color="primary" :value="item.id"
                  hide-details></v-checkbox>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
      </v-card>
      <v-row class="btn justify-center">
        <v-btn depressed color="primary" class="btn-save" @click="saveData">
          Save
        </v-btn>
        <v-btn depressed color="error" class="btn-save" @click="clearData">
          Cancel
        </v-btn>
      </v-row>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    employeeScanPoint: {},
    employee: {},
  },
  data() {
    return {
      btnLoading: false,
      data: {
        clock_in_zone_id: [],
        employee_id: "",
      },
    };
  },
  methods: {
    fetchScanPoint(val) {
      this.$emit('search', val);
    },
    saveData() {
      this.data.employee_id = this.employee.id;
      this.$axios
        .post(`company/employee-scan-point`, this.data)
        .then((res) => {
          this.data = {
            clock_in_zone_id: [],
            employee_id: "",
          };
          if (res.data.code === 200) {
            this.$emit("close");
            this.$emit("success");
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    clearData() {
      this.data = {
        clock_in_zone_id: [],
        employee_id: "",
      };
      this.$emit("close");
      //
    },
  },
};
</script>

<style scoped lang="scss">
.content-layout {
  padding: 20px;
  font-family: "Noto Sans Lao" !important;
}

.btn {
  padding: 30px !important;
}

.btn-save {
  color: white !important;
  margin-right: 30px;
}

.user {
  color: blue;
}
</style>