<template>
  <div class="main-container">
    <div class="section-topic">
      <div class="title-header">
        <h1>{{ $t("ScanPoint.title") }}</h1>
      </div>
    </div>
    <hr />
    <br />
    <div class="section-action">
      <v-row>
        <v-col cols="12" md="3" lg="3">
          <label class="label-input">{{ $t("ScanPoint.search_title") }}</label>
          <v-text-field class="btn btn-color btn-border btn-border-color" outlined dense prepend-inner-icon="mdi-magnify"
            :placeholder="$t('Search.search')" v-model="searchItem" @keypress.enter="fetchEmployeeScanPoint">
          </v-text-field>
        </v-col>
        <v-col cols="12" md="4" lg="4">
          <label class="label-input">{{ $t("ScanPoint.dept") }}</label>
          <v-select class="btn btn-color btn-border btn-border-color" outlined dense placeholder="..." hide-details
            :items="listDepartment" item-value="id" item-text="name" v-model="department_id"
            @change="fetchEmployeeScanPoint">
          </v-select>
        </v-col>
        <v-col cols="12" md="4" lg="4">
          <label class="label-input">{{ $t("ScanPoint.branch") }}</label>

          <v-select class="btn btn-color btn-border btn-border-color" outlined dense placeholder="..." hide-details
            :items="listBranch" item-value="id" item-text="branch_name" v-model="branch_id"
            @change="fetchEmployeeScanPoint">
          </v-select>
        </v-col>
      </v-row>
    </div>
    <div class="section-form-content">
      <div class="block-content">
        <table class="table table-view" v-if="listScanPoint.length > 0">
          <thead>
            <tr>
              <th class="text-left">No</th>
              <th class="text-left">{{ $t("ScanPoint.table.emp_number") }}</th>
              <th class="text-left">{{ $t("ScanPoint.table.emp_name") }}</th>
              <th class="text-left">{{ $t("ScanPoint.table.dept") }}</th>
              <th class="text-left">
                {{ $t("ScanPoint.table.scan_point") }}
              </th>

              <th class="text-left"></th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item, idx) in listScanPoint" :key="idx">
              <td>{{ pagination.current_page * 10 - 10 + idx + 1 }}</td>
              <td>
                {{ item.emp_number }}
              </td>
              <td>{{ item.name }} {{ item.surname }}</td>
              <td>
                {{ item.department.name }}
              </td>
              <td>
                <span class="scan-point"><i class="fal fa-map-marker-alt"></i>
                  {{
                    item.default_scan_point
                    ? item.default_scan_point.zone_name
                    : "null"
                  }}</span>

                <span v-for="(sp_item, index) in item.scan_point" :key="index" class="scan-point"><i
                    class="fal fa-map-marker-alt"></i>
                  {{ sp_item ? sp_item.zone_name : "null" }}
                  <a class="btn-delete" @click="deleteScanPoint(sp_item.scan_point_id)"><i class="fa fa-trash"
                      aria-hidden="true"></i></a></span>
              </td>
              <td>
                <v-btn class="mx-2" fab dark small color="primary" @click="onAddNew(item.id)">
                  <v-icon dark> mdi-plus </v-icon>
                </v-btn>
              </td>
            </tr>
          </tbody>
        </table>
        <defaultTableNoResult v-else />

        <Pagination class="mt-5 mb-5" v-if="pagination.total_pages > 1" :pagination="pagination" :offset="offset"
          @paginate="fetchEmployeeScanPoint">
        </Pagination>
        <Loading v-if="isLoading" />
        <ModalEdit fullscreen :title="$t('ScanPoint.add_new.title')">
          <template v-slot="{ close }">
            <addNew :employeeScanPoint="employeeScanPoint" :employee="employee" @close="close"
              @success="fetchEmployeeScanPoint" @search="(val) => searchScanPoint(val)" />
          </template>
        </ModalEdit>
      </div>
    </div>
  </div>
</template>

<script>
import Pagination from "../../../components/Paginate/Pagination";
import Loading from "../../../components/Loading";
import defaultTableNoResult from "../../../components/defaultTableNoResult";
import addNew from "@/views/MainCompany/ScanPoint/layouts/addNew";

export default {
  components: {
    Pagination,
    Loading,
    defaultTableNoResult,
    addNew,
  },
  data() {
    return {
      employeeScanPoint: [],
      employee: "",
      dialog: false,
      model: null,
      offset: 10,
      pagination: {},
      per_page: 10,
      filter: "",
      filter_employee_id: "",

      isLoading: true,
      listScanPoint: [],
      department: {},
      departmentId: "",
      searchItem: "",
      listDepartment: [],
      department_id: "",
      listBranch: [],
      branch_id: "",
    };
  },
  methods: {
    deleteScanPoint(id) {
      this.isLoading = true;
      this.$axios
        .delete(`company/employee-scan-point/${id}`)
        .then((res) => {
          this.isLoading = false;
          this.fetchEmployeeScanPoint();
        })
        .catch((error) => {
          console.log(error);
        });
    },
    searchFilterItem() {
      this.fetchEmployeeScanPoint();
    },
    filterScanPoint(id) {
      return (
        this.listScanPoint.filter((item) => {
          return item.id == id;
        })[0] || {}
      );
    },
    searchScanPoint(val) {
      this.filter = val;
      this.fetchScanPoint();
    },

    onAddNew(emp_id) {
      this.filter_employee_id = emp_id;
      this.fetchScanPoint();
    },

    fetchScanPoint() {
      this.isLoading = true;
      this.$axios
        .get(`company/clockInZone/${this.filter_employee_id}`, {
          params: {
            filter: this.filter
          }
        })
        .then((res) => {
          if (res.status === 200) {
            this.employeeScanPoint = res.data.data;
            this.employee = this.filterScanPoint(this.filter_employee_id);
            this.isLoading = false;
            this.$store.commit("modalEdit_State", true);
          }
        })
        .catch((error) => {
          console.log(error);
          this.isLoading = false;
        });
    },

    fetchEmployeeScanPoint() {
      this.isLoading = true;
      this.$axios
        .get(`company/employee-scan-point`, {
          params: {
            page: this.pagination.current_page,
            per_page: this.per_page,
            filter: this.searchItem,
            department_id: this.department_id,
            branch_id: this.branch_id,
          },
        })
        .then((res) => {
          if (res.status === 200) {
            this.isLoading = false;
            this.listScanPoint = res.data.data.data;
            this.pagination = res.data.data.pagination;
            if (!this.listScanPoint.length > 0) {
              this.pagination.current_page = this.pagination.current_page - 1;
            }
          }
          this.isLoading = false;
        })
        .catch((error) => {
          this.isLoading = false;
        });
    },
    fetchDepartment() {
      this.$axios
        .get(`company/list/departments/selected`, {})
        .then((res) => {
          if (res.status === 200) {
            this.listDepartment = res.data.data;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    fetchBranch() {
      this.$axios
        .get(`company/list/company/branches`)
        .then((res) => {
          if (res) {
            this.listBranch = res.data.companyBranches;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
  created() {
    this.fetchEmployeeScanPoint();
    this.fetchDepartment();
    this.fetchBranch();
  },
};
</script>

<style scoped lang="scss">
.scan-point {
  background: rgb(68, 135, 241) !important;
  color: white !important;
  margin: 4px;
  padding: 5px;
  font-size: 12px;
  border-radius: 25px;

  a {
    color: rgb(216, 49, 49);
  }
}
</style>
